<template>
    <div >
        
       
        <vx-card>
            <callHeader :titre="cours" :coach="coach" :debut="begin"></callHeader>
        </vx-card>
        <br>
        <vx-card>
            
            <vs-row>
                <br>
                <vs-col  vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                    <!-- <vx-card  style="margin:15px;" fixedHeight> -->
                        <vs-table stripe :data="pack"
                        id="tablee"
                        pagination
                        max-items="10"
                        noDataText="Aucun éléments"
                        class="table-responsive"
                       >
                            <template slot="header">
                                <h3>
                                Packs achetés
                                </h3>
                            </template>
                            <template slot="thead">
                                <vs-th sort-key="name">Prénom Nom</vs-th>
                                <vs-th sort-key="expires">Expiration</vs-th>
                                <vs-th sort-key="credit">Credit</vs-th>
                                 
                            </template>

                            <template slot-scope="{data}">
                                <vs-tr :key="indextr" v-for="(tr, indextr) in data"  >
                                   
                                    <vs-td :data="data[indextr].name" >
                                    <p @click="handledSelected(indextr)"> {{data[indextr].name}} </p>
                                    </vs-td>

                                    <vs-td :data="data[indextr].expires"  >
                                    <p @click="handledSelected(indextr)"> {{data[indextr].expires}} jour(s)</p>
                                    </vs-td>

                                    <vs-td :data="data[indextr].credit" >
                                    <p @click="handledSelected(indextr)">  {{data[indextr].credit}} </p>
                                    </vs-td>

                                
                            
                                </vs-tr>
                            </template>
                        </vs-table>
                   <!-- </vx-card> -->
                </vs-col>
                
                
            
                <vs-col  vs-type="flex" vs-justify="center" vs-align="center" vs-w="6"  >
                  <!-- <vx-card  style="margin 15px;" fixedHeight> -->
                    <vs-table stripe id="tablee" pagination
                        max-items="10" :data="present" style="padding-left:2%;"  noDataText="Aucun élément" class="table-responsive">
                            
                            <template slot="header">
                                <h3>
                                Réservations enregistrées
                                </h3>
                            </template>
                            <template slot="thead">
                                <vs-th sort-key="name">Prénom Nom</vs-th>
                                <vs-th sort-key="expires">Expire</vs-th>
                                <vs-th sort-key="credit">Credit</vs-th>
                                
                            </template>

                            <template slot-scope="{data}">
                                <vs-tr :key="indextr" v-for="(tr, indextr) in data"  >
                                    <vs-td :data="data[indextr].name">
                                       <p  @click="suppM=true; ind=indextr " > {{data[indextr].name}} </p>
                                    </vs-td>
           
                                    <vs-td :data="data[indextr].expires" align="right">
                                       <p @click="suppM=true; ind=indextr" > {{data[indextr].expires}} </p>
                                    </vs-td>

                                    <vs-td :data="data[indextr].credit">
                                       <p  @click="suppM=true; ind=indextr" > / {{data[indextr].credit}} </p>
                                    </vs-td>
                                     <vs-popup
                                        
                                        title="Suppression"
                                        :active.sync="suppM" >
                                            <div align="center">
                                                Confirmer la suppression ?  
                                            </div>
                                            <br>
                                            <vs-divider/>
                                            <vs-row>
                                                <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                                                    <div></div>
                                                    <vs-button color="primary" type="filled" v-on:click="suppM=false" icon-pack="feather">Annuler</vs-button>
                                                    <vs-button color="success" type="filled" v-on:click="dialog(ind); suppM=false;" icon-pack="feather">Supprimer</vs-button>
                                                    <!--<vs-button color="success" type="filled" v-on:click="add(1)" icon-pack="feather" icon="icon-plus">Ajouter</vs-button>-->
                                                </vs-col>
                                            </vs-row>

                                     </vs-popup>

                                    


                                </vs-tr>
                            </template>
                        </vs-table>
                        
                        <br>
                  <!-- </vx-card> -->
                </vs-col>
            </vs-row>      
                    
            <vs-row>
                 <vs-col  vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" vs-s="3">
                    
                 </vs-col>
                  <vs-col  vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" vs-s="3">
                    <!-- <vs-button type="filled" @click="add=true">Ajouter</vs-button> -->
                    <vs-button type="filled" @click="addResa()">Ajouter</vs-button>
                  </vs-col>

                   <vs-popup
                        @cancel="val='Annuler'"
                        title="Ajouter"
                        :active.sync="add"
                        >

                        <div class="con-exemple-prompt" align="center">
                            <vs-button type="filled" class="w-full" color='success' size="large" @click="add=false; essai=true"> Membre essai </vs-button>
                            <br>
                            <br>
                            <vs-button  type="filled" class="w-full" variant="info" size="large" @click="add=false; getMember()"> Membre </vs-button>
                         
                        </div>
                        <vs-divider/>
                        <vs-row>
                            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                                <div></div>
                                <vs-button color="primary" type="flat" v-on:click="add=false" icon-pack="feather">Annuler</vs-button>
                                <!--<vs-button color="success" type="filled" v-on:click="add(1)" icon-pack="feather" icon="icon-plus">Ajouter</vs-button>-->
                            </vs-col>
                        </vs-row>

                    </vs-popup>
                    <vs-popup
                        @cancel="val='Annuler'"
                        title="Ajouter un essai"
                        :active.sync="essai"
                        >

                        <div class="con-exemple-prompt" align="center">
                           <template>
                                
                                <div class="vx-row mb-6">
                                    <div class="vx-col sm:w-1/3 w-full">
                                    <span>Nom</span>
                                    </div>
                                    <div class="vx-col sm:w-2/3 w-full">
                                    <vs-input type="text" class="w-full" icon-pack="feather" icon="icon-user" icon-no-border v-model="nom_ess" />
                                    </div>
                                </div>
                                <div class="vx-row mb-6">
                                    <div class="vx-col sm:w-1/3 w-full">
                                    <span>Prénom</span>
                                    </div>
                                    <div class="vx-col sm:w-2/3 w-full">
                                    <vs-input type="text" class="w-full" icon-pack="feather" icon="icon-user" icon-no-border v-model="prenom_ess" />
                                    </div>
                                </div>
                                
                                    
                                <div class="vx-row mb-6">
                                    <div class="vx-col w-full" >
                                        <vue-tel-input class="w-full"  v-model="tel_ess" style="height:40px"></vue-tel-input>
                                    </div>
                                </div>
                                
                                
                                
                                
                                </template>
                        </div>
                        <vs-divider/>
                        <vs-row>
                            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                                <div></div>
                                <vs-button color="primary" type="flat" v-on:click="essai=false" icon-pack="feather">Annuler</vs-button>
                                <vs-button color="success" type="flat" v-on:click="addEssai(); essai=false" icon-pack="feather">Ajouter</vs-button>
                                <!--<vs-button color="success" type="filled" v-on:click="add(1)" icon-pack="feather" icon="icon-plus">Ajouter</vs-button>-->
                            </vs-col>
                        </vs-row>

                    </vs-popup>
                    <vs-popup
                        @cancel="val='Annuler'"
                        title="Rechercher un membre"
                        :active.sync="chargement">
                        <h3>Suppression en cours ...</h3>
                    </vs-popup>
                        
                    <vs-popup
                        @cancel="val='Annuler'"
                        title="Rechercher un membre"
                        :active.sync="membre"
                        >

                        <div class="con-exemple-prompt" align="center">
                        <vs-table
                            id="tablee"
                            pagination
                            max-items="10"
                            search
                            :data="listMembre"
                            noDataText="Aucun résultat"
                             class="table-responsive"
                            >

                    

                                <template slot="thead">
                                    <vs-th sort-key="prenoms">Prénom</vs-th>
                                    <vs-th sort-key="noms">Nom</vs-th>
                                    <vs-th sort-key="tel">Telephone</vs-th>
                                    
                                </template>

                                <template slot-scope="{data}">
                                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                                        <vs-td :data="data[indextr].noms">
                                          <p @click="addMembers(indextr,data[indextr].ui)">   {{ data[indextr].noms }}</p>
                                        </vs-td>
                                        <vs-td :data="data[indextr].prenoms">
                                          <p @click="addMembers(indextr,data[indextr].ui)">  {{ data[indextr].prenoms }} </p>
                                        </vs-td>
                                        <vs-td :data="data[indextr].tel" class="nowrap">
                                          <p @click="addMembers(indextr,data[indextr].ui)">  {{ data[indextr].tel }}</p>
                                        </vs-td>
                                       
                                    </vs-tr>
                                </template>
                            </vs-table>
                        </div>
                        <vs-divider/>
                        <vs-row>
                            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                                <div></div>
                                <vs-button color="primary" type="flat" v-on:click="membre=false" icon-pack="feather">Annuler</vs-button>
                                <!--<vs-button color="success" type="filled" v-on:click="add(1)" icon-pack="feather" icon="icon-plus">Ajouter</vs-button>-->
                            </vs-col>
                        </vs-row>
                    </vs-popup>
                    <!-- <vs-col  vs-type="flex" vs-justify="center" vs-align="center" vs-w="4" vs-s="3">
                    
                    </vs-col> -->
                    
                </vs-row>
                
                        
                
            
         
            <!-- <vs-popup classContent="popup-example"  title="Lorem ipsum dolor sit amet" :active.sync="popupActivo2">
                
                Confirmez la suppression ?
                <br>
                <vs-button @click="remake()"> Oui  </vs-button> 
                <vs-button > Non </vs-button>
            </vs-popup> -->
    
          
        </vx-card>
        <br>
         <vs-button @click="back()"> Retour </vs-button>
        <SelectAddPopup ref="viewSelectAdd" v-on:select="typeResaSelect"  />
        <AddPopup ref="addBooking" v-on:refresh="relodBooking" :creditS="this.credit"/>
        <AddMember ref="addMember" v-on:refresh="relodBooking" />

       
    </div>
  
</template>


<script>

import VxCard from '@/components/vx-card/VxCard.vue'
import callHeader from '@/components/booking/callHeader'
import Member  from '@/database/models/member'
import Booking  from '@/database/models/booking'
import Item  from '@/database/models/item';
import Machine  from '@/database/models/machine';
import BookingHour  from '@/database/models/booking_hour';
import moment from 'moment'
import autoRefresh from '@/assets/utils/autoRefresh'
import Fdate from '@/assets/utils/fdate';
import SelectAddPopup from '@/components/booking/selectAdd.vue'
import AddMember from '@/components/member/add.vue'
import AddPopup from '@/components/booking/addMemberE.vue'
import Credit from '@/assets/utils/credit';



export default {
  components: { 
      VxCard, 
      callHeader,
      Member,
      Item,
      Machine,
      BookingHour,
      autoRefresh,
      Booking,
      Fdate,
      SelectAddPopup,
      AddMember,
      AddPopup,
      Credit
      

    },
    name:"calling",
    data(){
        return{
            date:this.$route.query.date,
            uid:this.$route.query.uid,
            credit:0,
            cours:"",
            begin:"",
            duree:"",
            coach:"",
            pack:[],
            present:[],
            add:false,
            essai:false,
            membre:false,
            machin:"",
            listMembre:[],
            expi:"",
            uidpres:[],
            chargement:false,
            nom_ess:"",
            prenom_ess:"",
            tel_ess:"",
            suppM:false,
            ind:""
            
        }
    },
   watch:{
       present:async function(){
           
           //console.log(" list present : ", this.present, " pack : ", this.pack)
           
       }
   },
   
    mounted:function()
	{
        
        autoRefresh.SetCallback(async()=>
        {
           
            await this.getResa();
            await this.getInfo()
           
           
        })
        autoRefresh.refresh();
  },
    
    methods:{
        relodBooking(){
            autoRefresh.refresh();
        },
         addResa(  ){
            
            this.$refs.viewSelectAdd.openPopup(false, false)
        },
        typeResaSelect(type)
        {
            if(type=="e")
            {
                this.$refs.addMember.openPopup((uid_member)=>
                {
                    this.addEs(uid_member)
                    
                })
                return
            }
            let d = moment(this.date,"MM-DD-YYYY").toDate()
            
                this.$refs.addBooking.openPopup( d, this.machin, this.begin, type )

            // else if(type=="m"){
            //     this.membre=true;
            //     this.getMember()
                
            // }
            // else if ( type=="c") {
            //     let d = moment(this.date,"MM-DD-YYYY").toDate()
            
            //     this.$refs.addBooking.openPopup( d, this.machin, this.begin, type )

            // }
            
            if(type=='b')
                this.$refs.addBooking.add(0,0)

            

        },
        async addEssai(){
            
            
            if(this.nom_ess!="" && this.prenom_ess!="" && this.tel_ess!=""){
                Member.add({
                    first_name : this.nom_ess,
                    last_name  : this.prenom_ess,
                    phone      : this.tel_ess.split(' ').join(''),
                    active     : 1,
                    type       : 'e',
                })
                .then((uid)=>{
                    // console.log(" uid ", uid)
                    // let members =  Member.AwaitGetAllMemory();
                    // console.log(" member : ", members)
                   this.addEs(uid)
                   this.membre = false
                        this.$vs.notify({
                        color:'success',
                        title:'Ajout Membre',
                        text:'Membre enregistré'
                    })     
                })
                .catch(()=>
                {
                    this.$vs.notify({
                            color:'danger',
                            title:'Ajout Membre',
                            text:'Membre échoué'
                        })
                });
            }
            
        },
        addEs(uid){
            let v = this.date +" "+ this.begin
            let x = moment(v,"MM-DD-YYYY HH:mm").toDate();
             Booking.add({
                    date     : Fdate.getISO_8601(x),
                    machine  : this.machin,
                    member   : uid,
                    map      : 0,
                })
                .then(()=>{
                    
                    this.membre = false
                        this.$vs.notify({
                        color:'success',
                        title:'Ajout réservation',
                        text:'Réservation effectué'
                    })
                    
                   autoRefresh.refresh()
                    
                })
                .catch(()=>{
                    this.membre = false
                        this.$vs.notify({
                        color:'danger',
                        title:'Ajout réservation',
                        text:'Réservation échoué'
                    })
                })
            this.nom_ess="";
            this.prenom_ess="";
            this.tel_ess="";
        },
        async addMembers(tr,ui){
            let members = await Member.AwaitGetAllMemory();
            var l;
           
            for (let j in members){
                if(members[j].uid==ui ){
                    
                        let n = members[j].first_name+" "+members[j].last_name
                        let c = members[j].credit;
                        let e = 0;
                        
                         l={
                            name:n,
                            credit:c,
                            expires:e,
                            type:"m"
                            
                        }
                        
                        break;
                    }
            }
            //let d = await Booking.AwaitGetAllMemory();
            //console.log(" d : ",d )
            // let n = l.name;
            // let w = l.credit;
            // let e = 0;
            let v = this.date +" "+ this.begin
            let x = moment(v,"MM-DD-YYYY HH:mm").toDate();
          /// this.membre=false
           // console.log(" n ",n,"c",w,"e ",e,"m "," l ",l,"::: ", this.listMembre[0])
            Booking.add({
                    date     : Fdate.getISO_8601(x),
                    machine  : this.machin,
                    member   : ui,
                    map      : 0,
                })
                .then(()=>{
                    
                    this.membre = false
                        this.$vs.notify({
                        color:'success',
                        title:'Ajout réservation',
                        text:'Réservation effectué'
                    })
                    
                    
                    
                    // let l={
                    //         name:n,
                    //         credit:c,
                    //         expires:e
                    //     }
                    
                    this.present.push(l)
                    autoRefresh.refresh();
                })
                .catch(()=>{
                    this.membre = false
                        this.$vs.notify({
                        color:'danger',
                        title:'Ajout réservation',
                        text:'Réservation échoué'
                    })
                })
            // // let c = Booking.AwaitGetAllMemory();
            
        },
          suppr(indextr){
             //this.chargement=true;
             //console.log(" index : ", indextr)
            let d = this.getIndex(this.uidpres,this.present[indextr].u  )
            this.uidpres.splice(d,1)
            Booking.remove(this.present[indextr].uidd)
            .then(async()=>{
                autoRefresh.SetCallback(async()=>
                {
                
                    await this.getResa();
                    await this.getInfo()
                
                
                })
                autoRefresh.refresh();
                this.$vs.notify({
                        color:'success',
                        title:'Suppression',
                        text:'Réservation supprimé'
                    })
                    
            })
            .catch(()=>{
                    this.$vs.notify({
                        color:'danger',
                        title:'Suppression',
                        text:'Echec suppression réservation'
                    })
            });
            
           
            //this.chargement=false;
        },
        dialog(index){
            //console.log(" index : ", index)
            this.id=index,
            this.suppr(index)
            this.suppM=false;

        },
        cancel(){
            
        },
        handledSelected(indextr) {
           let v = this.date +" "+ this.begin
            let x = moment(v,"MM-DD-YYYY HH:mm").toDate();
           // console.log(" : ", this.machin, " : ",this.pack[indextr].u)
            Booking.add({
                    date     : Fdate.getISO_8601(x),
                    machine  : this.machin,
                    member   : this.pack[indextr].u,
                    map      : 0,
                })
                .then(()=>{
                    
                    this.membre = false
                        this.$vs.notify({
                        color:'success',
                        title:'Ajout réservation',
                        text:'Réservation effectué'
                    })
                    
                    
                    
                   
                
                    
                    
                    autoRefresh.refresh();
                })
                .catch(()=>{
                    this.membre = false
                        this.$vs.notify({
                        color:'danger',
                        title:'Ajout réservation',
                        text:'Réservation échoué'
                    })
                })
            this.pack.splice(indextr,1);
            autoRefresh.refresh();
        },
        back(){
            let f = moment(new Date(),"").format("MM-DD-YYYY")
            this.$router.push("/admin/groups/"+f)
            autoRefresh.refresh()
        },
        async getResa(){
            // recupere les reservations faite 

            let Allhours = await BookingHour.AwaitGetAllMemory()
            //console.log(" all hours , ",Allhours)
            let mach=""         // la machine
            let debut=""
            for (let i in Allhours){
                if(Allhours[i].uid==this.uid){
                    debut=Allhours[i].hour;
                    mach=Allhours[i].machine;
                    break;
                }
            }
            this.machin=mach;
            this.begin=debut;
            let b = await Booking.AwaitGetAllMemory();
            
            // contient la liste des reservations 
            let listR =[]
            let uidR=[]
            for (let i in b ){
                if(b[i].machine==this.machin  ){
                    //console.log(" same machine")
                    let m = moment(b[i].date,"").format("MM-DD-YYYY")
                    let h = moment(b[i].date,"").format("HH:mm")
                    this.date = moment(this.date,"M-DD-YYYY").format("MM-DD-YYYY")
                    //console.log(" same machine h",h,"m",m, "begin : ", this.begin," date : ", this.date)
                    if( this.begin == h && this.date==m){
                       //console.log(" same hours")
                       listR.push(b[i].member)
                       uidR.push(b[i].uid)
                    }
                }
               
              // on ne tri pas par type de membre car 
              // tout type peuvent reserver   
            
            }
           
           // on recupere le nom et info des membres correspondant 
           // aux reservations 
            let mem = await Member.AwaitGetAllMemory()
            this.present=[]
            let lk =[]
            
            for (let i in listR){
                
                for (let j in mem){
                    if(listR[i]==mem[j].uid){
                        
                        let n = mem[j].first_name+" "+mem[j].last_name
                        let c = mem[j].credit;
                        let e =  mem[j].expire;
                        let ud = mem[j].uid;
                        let l={
                            name:n,
                            credit:c,
                            expires:e,
                            u:ud,
                            uidd:uidR[i],
                            type:"a"
                            
                        }
                        if( this.getIndex(this.uidpres, ud)==-1)
                            this.uidpres.push(ud)   // pour avoir la liste des uid presents 

                        lk.push(l)
                        break;
                    }
                }
            }
            
            for(let i in lk){
                this.present.push(lk[i])
                
            }
            //this.relodBooking()
           // console.log(" ok ",lk)
        },
        async getExp(uid){
           // on recupere pour chaque reservations faite la date d'expiration 
           let items = await Item.AwaitGetAllMemory();
           // on récupere la date de creation 
           let cree = items[uid].createAt;
           let ex = items[uid].expire;
          
           let today = new Date()

           // la date de fin 
           let x = new Date(cree);
           x.setDate(x.getDate() + ex)

           // combien de temps avant expiration 
           let diffff = x - today
            let diff = parseInt( diffff / (1000 * 3600 * 24));
           // console.log(" diff ", diff)
          if( diff<=0){
              return 0
          }
          else{
              return diff
          }

        },
        async getExpR(uid){
           // pareil que getExp mais pour les reservations 
           let items = await Item.AwaitGetAllMemory();
           // on récupere la date de creation 
           let cree = items[uid].createAt;
           let ex = items[uid].expire;
          
           let today = new Date()

           // la date de fin 
           let x = new Date(cree);
           x.setDate(x.getDate() + ex)

           // combien de temps avant expiration 
           let diffff = x - today
            let diff = parseInt( diffff / (1000 * 3600 * 24));
           // console.log(" diff ", diff)
          if( diff<=0){
              return 0
          }
          else{
              return diff
          }

        },
        getIndex(list,x){
            let d =-1;
            // permet de recuper l'index de ce qui est chercher
            for (let i in list){
                if(list[i]==x){
                    
                    return d=i;
                }
            }
            return d;
        },
        async getInfo(){
            //console.log(" uid list : ", this.uidpres)
            // on recupere les informations nécessaire 

            let members = await Member.AwaitGetAllMemory();
            //console.log(" members : ", members )
            let machines = await Machine.AwaitGetAllMemory();
            //console.log(" machine : ", machines)
            let Allhours = await BookingHour.AwaitGetAllMemory();
            //console.log(" all hours : ", Allhours)
            let items = await Item.AwaitGetAllMemory();
            //console.log(" items : ", items)

            // get coach uid et heure de debut 
            let ch =[]
            let coach_uid=""
           


            let mach=""


            // je parcour toutes les séances disponibles 
            for (let i in Allhours){
                if(Allhours[i].uid==this.uid){
                    //console.log(" ici ",Allhours[i] )
                    coach_uid=Allhours[i].coach;
                    // je recupere les infos de la séance correspondance

                    mach=Allhours[i].machine;
                    // je stocke le numero de la machine correspondante ainsi que la durée
                    this.duree=Allhours[i].duration;
                    ch.push(Allhours[i])
                    break;
                }
            }
            
            // get coach name 
            let coach_name ="";
            for(let i in members){
                if(members[i].uid==coach_uid){
                    // je recupere les infos du coach de la séance
                    coach_name=members[i].last_name+" "+members[i].first_name
                    break;
                }
                else if(coach_uid==null){
                    coach_name="Aucun Coach"
                    break;
                }
            }
            this.coach=coach_name;

            // get nom du cours 
            let nom=""
            for (let i in machines){
                if(machines[i].uid==mach){
                    // je récupere le nom de la séance
                    nom=machines[i].name;
                    break;
                }
            }
            this.cours=nom;

             // recuperer la list de items de cette machine 
            let listAbo=[];
            this.machin=mach;
            var a ;

            // regarder !!! si possible de l'avoir rapidement
            var cred;


            let machMin = mach ;
            mach= mach.charAt(0).toUpperCase()+ mach.slice(1)

            if (machines[machMin]!=undefined )
                this.credit=machines[this.machin].credit;
            else{
                this.credit=machines[mach].credit;
            }

            

            // je parcours les achats effectuée 
            for(let i in items){

                // je prends les achats pour les machines correspondantes aux séances 
                if(items[i].machine==mach || items[i].machine==machMin){

                    // je recupere les credit restant pour cette machine pour l'user
                    let caux = await Credit.getCredit( items[i].member );
                    let info = await BookingHour.AwaitGetAllMemory()

                    // je recupere la date de creation de la facture
                    let cre=items[i].createAt;
                    


                    // pour avoir la bonne machine 
                    if (caux[info[this.uid].machine]== undefined ){
                        let ma = info[this.uid].machine.charAt(0).toUpperCase() + info[this.uid].machine.slice(1);
                        this.machin=ma;
                        cred=caux[ma].credit; // credit restant à l'user
                    }
                    else{
                        this.machin=info[this.uid].machine;
                        cred=caux[info[this.uid].machine].credit;
                    }
        

                    

                    // je recupere les crédits nécessaire à la séances 
                    let exp= await this.getExp(items[i].uid)
                    a = exp;
                    //console.log("expire",exp," ", cre)

                    // console.log(" exp : ", exp)

                    // date de debut 
                    let deb = moment(cre,"").format("YYYY-MM-DD")

                    // date de fin
                    let fin = moment(deb,"YYYY-MM-DD").add(exp,"days").format("YYYY-MM-DD")
                    
                    
                    // aujourd'hui 
                    let today=moment(new Date(),"").format("YYYY-MM-DD");


                    // console.log(" cred =: ", cred, " membre ? ", members[items[i].member])
                    // console.log(" debut : ", deb, " fin : ", fin, "aujourdhhui : ", today )
                    

                    // ajourd'hui entre debut et fin 
                    // ou debut == aujourd'hui ou fin == aujourd'hui
                 /*   if( moment(today.toString()).isBetween(deb.toString(),fin.toString()) ){
                       // pk cred != 0 ..????? 
                        
                        // credit de la machine disposée par l'utilisateur
                       if(cred!=0){*/
                       
                    if( moment(today.toString()).isBetween(deb.toString(),fin.toString()) || moment(today.toString()).isSame(deb.toString()) || moment(today.toString()).isSame(fin.toString()) ){
                        if(cred!=0){
                            //console.log("item ", items[i])

                            listAbo.push(items[i])
                        }
                            
                    }
                    else if ( moment(today.toString()).isSame(deb.toString())){
                        if(cred!=0){
                            listAbo.push(items[i])
                        }
                    }
                    else if(  moment(deb.toString()).isSame(fin.toString()) ){
                        if(cred!=0){
                            listAbo.push(items[i])
                        }
                    }
                    else if(  moment(today.toString()).isSame(fin.toString()) ){
                        if(cred!=0){
                            listAbo.push(items[i])
                        }
                    }
                    
                }
            }
            this.expi = a;
           

            // recuperer le nom,prenom, credit et expire de chaque client 
            let abo=[];
            for (let i in listAbo){

                for (let j in members){
                    let caux = await Credit.getCredit( listAbo[i].member );
                    let info = await BookingHour.AwaitGetAllMemory()
                    // console.log(" vv ... ", caux[this.machin].credit, members[listAbo[i].member])
                    if(listAbo[i].member==members[j].uid  && caux[info[this.uid].machine].credit>0  && this.getIndex(this.uidpres,members[j].uid)==-1){
                        let n = members[j].first_name+" "+members[j].last_name
                        
                        
                        let e = await this.getExp(listAbo[i].uid);  // await car getExp est async
                        let ud=listAbo[i].member;
                        let c ; 
                        
                        if ( caux[mach]==undefined){
                            c=caux[machMin].credit;

                        }
                        else{
                            c=caux[mach].credit; 
                        }

                        

                        //console.log(" e - > ",e)
                        // console.log(" credit : ", c, " this.t", this.credit)
                        if( c >= this.credit )  {

                          let l={
                                name:n,
                                credit:c,
                                expires:e,
                                u:ud,
                               
                                
                            }
                            
                            abo.push(l)
                        }
                        
                        break;
                    }
                }
            }


            this.pack=abo;
            
            //console.log(" abos : ", abo)

           
           // this.relodBooking()
        },
        async getMember(){
            
            this.membre=true;
            let allMember = await Member.AwaitGetAllMemory();
           // console.log(" all member : ", allMember)
            let listM=[];
            for (let i in allMember){
               
                if(allMember[i].credit >= this.credit && allMember[i].expire>0 ){
                   // console.log(" ok ",allMember[i].credit, " type : ",allMember[i].type  )
                    let p = allMember[i].first_name;
                    let n = allMember[i].last_name;
                    let ph = allMember[i].phone; 
                    let u = allMember[i].uid;
                    let x ={
                        prenoms:p,
                        noms:n,
                        tel:ph,
                        ui:u,
                    }
                    listM.push(x);
                }
            }
            this.listMembre=listM;
            
        }
    }
}
</script>

<style lang="stylus">

#tablee{
    overflow-y: hidden
}

.popup-example
  .vs-input
    float left
    width 50%
    margin 10px
    margin-top 5px
  .con-select
    margin-left 10px
    width 50%
    margin-bottom 10px
</style>